import { airTableClient } from "app/Client";
import { IBuyerRecord } from "app/types/buyer";
import { IBuyerInvitation } from "app/types/buyerInvitation";
import { IUser } from "app/types/user";
import { initializeApp } from "firebase/app";
import { createUserWithEmailAndPassword, getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut, updatePassword } from "firebase/auth";
import { addDoc, collection, doc, getDocs, getFirestore, query, setDoc, where } from "firebase/firestore";
import { getStorage } from 'firebase/storage';
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSessionStorage } from 'usehooks-ts';

// Step 1: Define the context
interface UserContextType {
  userDocs: IUser[] | undefined | null;
  user: IUser | undefined | null;
  multiUserSelected: string | null;
  buyerRecord: IBuyerRecord | undefined;
  login: (email: string, password: string) => Promise<{ message: string }>;
  logout: () => Promise<void>;
  signup: (invitation: IBuyerInvitation, password: string) => Promise<void>;
  resetPassword: (currentPassword: string, newPassword: string) => Promise<void>;
  createUserDocWithExistingAuth: (invitation: IBuyerInvitation, uid: string) => Promise<void>;
  setSingleAuthUser: (user: IUser) => Promise<void>;
  reselectMultiUsers: () => Promise<void>;
}

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// Initialize Firebase
// console.group('Initialize Firebase');
export const app = initializeApp(firebaseConfig);
export const AUTH = getAuth(app);
export const DB = getFirestore(app);
export const STORAGE = getStorage(app);
// console.groupEnd();

export const firebaseCollections = {
  invitations: `buyer-invitations`,
  users: 'users'
}

// Step 2: Create the context object
export const UserContext = React.createContext<UserContextType | undefined>(undefined);

// Step 3: Create a provider component
type Props = {
  children: React.ReactNode;
};
export function UserProvider({ children }: Props) {
  const [user, setUser] = useState<IUser | undefined | null>(undefined);
  const [buyerRecord, setBuyderRecord] = useState<IBuyerRecord | undefined>(undefined);
  const [userDocs, setUserDocs] = useState<IUser[] | undefined | null>(undefined);
  const [multiUserSelected, setMultiUserSelected] = useSessionStorage<string | null>('multi-user-selected', null);


  const setSingleAuthUser = useCallback(async (user: IUser) => {
    const recordData = await airTableClient.getBuyer(user);
    setUser(user);
    setBuyderRecord(recordData.data as IBuyerRecord);
    setMultiUserSelected(user.id);
    setUserDocs(undefined);
    window.location.assign(`/`)
  }, [setMultiUserSelected])

  const reselectMultiUsers = useCallback(async () => {
    if (!user)
      return
    setMultiUserSelected(null)
    window.location.assign(`/`)
  }, [user, setMultiUserSelected])

  const init = useCallback(() => {
    try {
      onAuthStateChanged(AUTH, async (user) => {
        // console.log('onAuthStateChanged', user);
        if (user) {
          // get doc and get buyer
          // const userDoc = await getDoc(doc(DB, `users/${user.uid}`));
          const possibleUserDocs = await getDocs(
            query(
              collection(DB, `users`),
              where('uid', '==', user.uid)
            )
          );
          // console.log('users found', possibleUserDocs.docs)
          if (possibleUserDocs.size === 1) {
            const userDoc = possibleUserDocs.docs[0];
            const userItem = { id: userDoc.id, ...userDoc.data() } as IUser;
            // setuser and record
            const recordData = await airTableClient.getBuyer(userItem);
            setUser(userItem);
            setBuyderRecord(recordData.data as IBuyerRecord);
          } else if (possibleUserDocs.size > 1) {
            // console.log('More than 1 user doc');

            // check if user docs contains mock and admins?
            const allDocs = possibleUserDocs.docs.map(dd => ({ id: dd.id, ...dd.data() } as IUser));
            const adminDocs = allDocs.filter(dd => dd.role === 'admin');
            const mockDocs = allDocs.filter(dd => dd.role === 'mock');
            const buyerDocs = allDocs.filter(dd => dd.role === 'buyer');

            if (adminDocs.length) {
              // console.log('has admin doc');
              if (mockDocs.length === 1) {
                const recordData = await airTableClient.getBuyer(mockDocs[0]);
                setUser(mockDocs[0]);
                setBuyderRecord(recordData.data as IBuyerRecord);
              } else {
                // already had user selected?
                if (!multiUserSelected) {
                  // if there are more than 1 user doc, we need to ask user to choose which one to login as
                  // console.log('2 or more')
                  setUserDocs(mockDocs)
                  setUser(null);
                  setBuyderRecord(undefined)
                } else {
                  const userDoc = possibleUserDocs.docs.find(dd => dd.id === multiUserSelected);
                  const userItem = { id: userDoc?.id, ...userDoc?.data() } as IUser;
                  // setuser and record
                  const recordData = await airTableClient.getBuyer(userItem);
                  // console.log('before set state', userItem, recordData);
                  setUser(userItem);
                  setBuyderRecord(recordData.data as IBuyerRecord);
                }
              }
            } else {
              // already had user selected?
              if (!multiUserSelected) {
                // if there are more than 1 user doc, we need to ask user to choose which one to login as
                // console.log('2 or more')
                setUserDocs(buyerDocs)
                setUser(null);
                setBuyderRecord(undefined)
              } else {
                const userDoc = possibleUserDocs.docs.find(dd => dd.id === multiUserSelected);
                const userItem = { id: userDoc?.id, ...userDoc?.data() } as IUser;
                // setuser and record
                const recordData = await airTableClient.getBuyer(userItem);
                // console.log('before set state', userItem, recordData);
                setUser(userItem);
                setBuyderRecord(recordData.data as IBuyerRecord);
              }
            }
          } else {
            // no docs
            setUser(null)
            setBuyderRecord(undefined)
            setUserDocs(undefined)
            setMultiUserSelected(null)
          }
        } else {
          setUser(null)
          setBuyderRecord(undefined)
          setUserDocs(undefined)
          setMultiUserSelected(null)
        }
      })
    } catch (error) {
      console.log('onAuthStateChanged > Error');
      setUser(null)
      setBuyderRecord(undefined)
      setUserDocs(undefined)
      setMultiUserSelected(null)
    }
  }, [multiUserSelected, setUser, setBuyderRecord, setUserDocs, setMultiUserSelected])

  useEffect(() => {
    init()
  }, [init])

  const login = useCallback(async (email: string, password: string) => {
    // setUser(DUMMY_USER);
    try {
      await signInWithEmailAndPassword(AUTH, email, password);
      return {
        message: 'success'
      }
    } catch (error) {
      return {
        message: `${(error as any).message}`
      }
    }
  }, []);

  const logout = useCallback(async () => {
    await signOut(AUTH);
    setUser(null)
    setBuyderRecord(undefined)
    setUserDocs(undefined)
    setMultiUserSelected(null)
    window.location.assign('/')
  }, [setUser, setBuyderRecord, setUserDocs, setMultiUserSelected])

  const signup = useCallback(async (invitation: IBuyerInvitation, password: string) => {
    const { email, buyerId, name } = invitation;
    // use a different app and auth for the registration
    const user = await createUserWithEmailAndPassword(AUTH, email, password);
    await setDoc(doc(DB, `buyer-invitations/${invitation.id}`), { status: 'active' }, { merge: true });
    await addDoc(collection(DB, `users`), {
      email,
      role: 'buyer',
      uid: user.user.uid,
      buyerId,
      displayName: name
    });
  }, [])

  const resetPassword = useCallback(async (currentPassword: string, newPassword: string) => {
    if (!user)
      return

    // create a temp app
    const tempApp = initializeApp(firebaseConfig);
    const tempAuth = getAuth(tempApp);
    const tempLogin = await signInWithEmailAndPassword(tempAuth, user.email, currentPassword);
    const tempUser = tempLogin.user;
    await updatePassword(tempUser, newPassword)
  }, [user])

  const createUserDocWithExistingAuth = useCallback(async (
    invitation: IBuyerInvitation, uid: string
  ) => {
    const { email, buyerId, name } = invitation;
    await setDoc(doc(DB, `buyer-invitations/${invitation.id}`), { status: 'active' }, { merge: true });
    await addDoc(collection(DB, `users`), {
      email,
      role: 'buyer',
      uid: uid,
      buyerId,
      displayName: name
    });
  }, []);
  const contextValue: UserContextType = useMemo(() => ({
    user,
    userDocs,
    buyerRecord,
    multiUserSelected,
    login,
    logout,
    signup,
    resetPassword,
    createUserDocWithExistingAuth,
    setSingleAuthUser,
    reselectMultiUsers
  }), [
    user, userDocs, buyerRecord, multiUserSelected, login, logout, signup, resetPassword, 
    createUserDocWithExistingAuth, setSingleAuthUser, reselectMultiUsers
  ])

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
}

// Step 4: Create a consumer hook
export const useUserContext = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};
