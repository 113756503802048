import { menuController } from "@ionic/core/components";
import { IonContent, IonItem, IonLabel, IonList, IonMenu, IonPage } from "@ionic/react";
import { useGlobalContext } from "app/contexts/global-context";
import { useUserContext } from "app/contexts/user-context";
import dayjs from "dayjs";
import { chatSettings } from "index";
import { PropsWithChildren, useEffect } from "react";
import { Helmet } from "react-helmet";
import { BiMenu } from "react-icons/bi";
import { BsEnvelope, BsThreeDotsVertical } from "react-icons/bs";
import { FaBell } from "react-icons/fa";
import { useHistory } from "react-router";
import { twMerge } from "tailwind-merge";
import Container from "./Global/Container";
import LoadingScreen from "./Global/LoadingScreen";
import { useTranslation } from 'react-i18next';
import { Icon as Iconify } from '@iconify/react';

declare global {
  interface Window {
    $respond: { [key: string]: any }; // or whatever type
    __respond_settings: { [key: string]: any };
  }
}

export function AuthLayout({ children }: PropsWithChildren) {
  const { user, userDocs, buyerRecord, multiUserSelected } = useUserContext();
  const { mobileMenu, mobileSideProps, setMobileMenu, pushes, customSideIcon } = useGlobalContext();
  const { push } = useHistory();
  const { i18n, t: tran } = useTranslation();

  // change language function
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    console.group('user data logs')
    console.log(`user`, user)
    console.log(`userDocs`, userDocs)
    console.log(`buyer records`, buyerRecord)
    console.log('multiUserSelected', multiUserSelected)
    console.groupEnd();
  }, [user, userDocs, buyerRecord, multiUserSelected]);

  useEffect(() => {
    console.log('Auth Layout Guard');

    // push to login if no user or userDocs
    if (user === null && !userDocs) {
      console.log(`push to login`)
      push("/login");
    }else if (userDocs && userDocs.length > 1 && !multiUserSelected) {
      console.log(`push to gateway`)
      push(`/user-gateway`)
    }else{
      console.log('render auth layout children')
    }
  }, [user, userDocs, multiUserSelected, push]);

  // set identify for chats
  useEffect(() => {
    if (!buyerRecord) {
      return
    }

    if (chatSettings.respondIO) {
      // for respond io
      window.__respond_settings = {
        identifier: buyerRecord.fields["Email Address"],
        firstName: buyerRecord.fields["First Name"],
        lastName: buyerRecord.fields["Last Name"],
        // phone: String(buyerRecord.fields["Contact Number"]).replaceAll('-', ''),
        email: buyerRecord.fields["Email Address"],
        profilePic: null,
        // countryCode: 'MY',
        // language: 'en',
      }
      return
    }
  }, [buyerRecord])

  // if user is undefined keep loading
  if (user === undefined) {
    return <LoadingScreen />;
  }

  const renderLanguageSelector = (
    <div className="flex gap-2 px-4 items-center">
      <div
        className=" divide-white text-sm flex py-2"
      >
        <button
          className={twMerge(
            `px-1.5 border-solid hover:underline underline-offset-4`,
            i18n.language === 'en' ? `underline` : ``
          )}
          onClick={() => changeLanguage('en')}
        >EN</button>
        <button
          className={twMerge(
            `px-1.5 border-solid hover:underline underline-offset-4`,
            i18n.language === 'zh' ? `underline` : ``
          )}
          onClick={() => changeLanguage('zh')}
        >繁</button>
        <button
          className={twMerge(
            `px-1.5 border-solid hover:underline underline-offset-4`,
            i18n.language === 'cn' ? `underline` : ``
          )}
          onClick={() => changeLanguage('cn')}
        >简</button>
      </div>
    </div>
  )

  const renderNotificationButton = (
    <div className="flex gap-2 px-4 py-2 items-center cursor-pointer">
      <FaBell
        className={twMerge(
          `w-5 h-5 relative`,
          pushes?.filter(pu => !pu.read).length ? `fill-yellow-500 animate-bounce` : ``
        )}
        onClick={() => {
          push("/notifications");
        }}
      />
    </div>
  )

  return (
    <>
      {/* mobile */}
      <IonMenu side="start" menuId="main-menu" contentId="main">
        <IonContent color='primary'>
          <img src="/assets/logos/Fulcrum Global Logo_Fulcrum Global-White.png"
            alt="Fulcrum Global"
            className="py-8 pl-4 w-48 h-auto"
          />
          <IonList color="primary" lines="none" >
            <IonItem
              color={'primary'}
              button
              onClick={() => {
                menuController.close("main-menu");
                push("/");
              }}
            >
              <IonLabel>{tran(`Home`)}</IonLabel>
            </IonItem>
            <IonItem
              button
              color={'primary'}
              onClick={() => {
                menuController.close("main-menu");
                push("/my-properties");
              }}
            >
              <IonLabel>{tran(`My Properties`)}</IonLabel>
            </IonItem>
            <IonItem
              button
              color={'primary'}
              onClick={() => {
                menuController.close("main-menu");
                push("/services");
              }}
            >
              <IonLabel>{tran(`Value-Added Services`)}</IonLabel>
            </IonItem>
            <IonItem
              button
              color={'primary'}
              onClick={() => {
                menuController.close("main-menu");
                push("/notifications");
              }}
            >
              <IonLabel>{tran(`Notifications`)}</IonLabel>
            </IonItem>
            <IonItem
              button
              color={'primary'}
              onClick={() => {
                menuController.close("main-menu");
                push("/account/guideline");
                // window.location.assign('/account/guideline');
              }}
            >
              <IonLabel>{tran(`My Account`)}</IonLabel>
            </IonItem>
          </IonList>
          <div className="mt-10 flex flex-col gap-4">
            {renderLanguageSelector}
          </div>
        </IonContent>
      </IonMenu>

      {/* mobile side menu */}
      <IonMenu side="end" menuId="side-menu" contentId="main">
        <IonContent>
          {mobileSideProps}
        </IonContent>
      </IonMenu>

      <IonPage id="main">
        {
          user ?
            <Helmet>
              {
                user && chatSettings.sleekflow ? <script
                  src="assets/js/sleekflow-custom.js"
                  data-id="travischatwidget"
                  data-companyid={process.env.REACT_APP_SLEEKFLOW_COMPANY_ID}
                  type="text/javascript"
                ></script> : null
              }
              {
                user && chatSettings.tidio ? <script src={`//code.tidio.co/wnxyzs2xdrx7fk09yedzdnlviggywe8e.js`} ></script> : null
              }
              {
                user && chatSettings.respondIO && buyerRecord ?
                  // fulcrum growth widget
                  <script id="respondio__growth_tool" src="https://cdn.respond.io/widget/widget.js?wId=36359b5e-7e72-4731-b719-682d118904c3"></script>
                  : null
              }
              {/* {
                user && chatSettings.zoho && buyerRecord ?
                <script type="text/javascript" id="zsiqchat">var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "siq211454addd800abd2aada02d7c1e1c5ded9a6bf4842f5fe5ccb37f2e3dc4731e", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.eu/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);</script>
              } */}
            </Helmet> : null
        }
        <IonContent fullscreen>
          <div className="w-screen h-screen flex flex-col">
            {/* desktop header */}
            <header className="hidden md:block w-full bg-gradient-to-b from-primary to-primary-fade text-white pt-8 pb-4">
              <Container>
                <div className={twMerge(`w-full grid grid-cols-12 gap-4`)}>
                  <img alt="" src="/assets/logos/Fulcrum Global Logo_Fulcrum Global-White.png" className="col-span-3 max-w-[256px] cursor-pointer"
                    onClick={() => push('/')}
                  />
                  <div className="col-span-9 flex flex-row justify-end items-center divide-x">
                    <small className="px-4">{buyerRecord?.fields["Buyer Name"]}</small>
                    {renderLanguageSelector}
                    {renderNotificationButton}
                  </div>
                </div>
              </Container>
            </header>

            {/* mobile header */}
            <header className={twMerge(`sticky md:hidden p-2 w-full flex items-center justify-between`, `bg-gradient-to-b from-primary to-primary-fade text-white`)}>
              <button
                onClick={async () => {
                  setMobileMenu(!mobileMenu);
                  await menuController.enable(true, "main-menu");
                  await menuController.open("main-menu");
                }}
              >
                <BiMenu className="w-8 h-8" />
              </button>
              <button onClick={() => push("/")}>
                <img alt="" src="/assets/logos/Fulcrum Global Logo_Fulcrum Global-White.png" className="col-span-3 w-auto h-6 object-contain" />
              </button>
              {
                mobileSideProps ?
                  <button
                    onClick={async () => {
                      await menuController.enable(true, "side-menu");
                      await menuController.open("side-menu");
                    }}
                  >
                    {
                      customSideIcon ?
                        <Iconify icon={customSideIcon} className="w-5 h-5" />
                        :
                        <BsThreeDotsVertical className="w-6 h-6" />
                    }
                  </button>
                  :
                  <div className="w-4 h-4"></div>
              }
            </header>

            <main className="m-auto grow w-full max-w-7xl grid grid-cols-12 gap-8">{children}</main>

            {/* footer */}
            <footer className="w-full bg-gradient-to-t from-primary to-primary-fade p-4">
              <div className="w-full max-w-7xl m-auto flex flex-col md:flex-row gap-4 justify-start items-center text-white">
                <div className="flex flex-col gap-2 order-2 md:order-1 w-full md:w-fit">
                  <small className="divide-y md:divide-y-0 md:divide-x flex flex-col md:flex-row">
                    <a className="py-2 md:pr-2 md:py-0 no-underline hover:underline text-white" href="/account/privacy">
                      {tran(`Privacy Policy`)}
                    </a>
                    <a className="py-2 md:px-2 md:py-0 no-underline hover:underline text-white" href="/account/terms-and-conditions">
                      {tran('Terms & Conditions')}
                    </a>
                    {/* <a className="py-2 md:px-2 md:py-0 no-underline hover:underline text-white" href="/sitemap.xml" >
                      {tran('Sitemap')}
                    </a> */}
                  </small>
                  <small
                    className="text-center md:text-start"
                  >&#169;{dayjs().format("YYYY")} {tran('Fulcrum Global (Hong Kong) Limited. All rights reserved.')}</small>
                </div>
                <a
                  href="mailto:support@flucrum.com"
                  className={twMerge(`order-1 md:order-2 my-8 w-fit rounded-full py-1 px-3 border-2 border-white text-white`, `flex items-center gap-2 justify-center`, `transition hover:bg-white hover:text-primary`)}
                >
                  <BsEnvelope className="w-4 h-4" />
                  <span>{tran(`Contact Us`)}</span>
                </a>
              </div>
            </footer>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
}
