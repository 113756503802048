import { useIonRouter } from "@ionic/react";
import useContentLocales from "app/contexts/use-content-locale";
import { useUserContext } from "app/contexts/user-context";
import { DB } from "app/contexts/user-context";
import { respondIOMessage } from "app/helpers";
import { IService, IServiceOption } from "app/types/services";
import { AuthLayout } from "components/AuthLayout";
import { Content } from "components/Content";
import { LoadingModule } from "components/Global/LoadingModule";
import { SideBar } from "components/SideBar";
import getSymbolFromCurrency from 'currency-symbol-map';
import { doc, getDoc } from "firebase/firestore";
import { chatSettings } from "index";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoPricetagOutline } from "react-icons/io5";
import { useParams } from "react-router";
import 'swiper/css';
import 'swiper/css/pagination';
import { A11y, Autoplay, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { twMerge } from "tailwind-merge";

const ServiceDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { push } = useIonRouter();
  const { buyerRecord } = useUserContext();
  const [rawService, setService] = useState<IService | undefined>(undefined);
  const { renderLocalizedData } = useContentLocales();
  const { t: tran, i18n } = useTranslation()

  useEffect(() => {
    const loadService = async () => {
      const res = await getDoc(doc(DB, `services/${id}`));
      setService({ id: res.id, ...res.data() } as IService);
    }
    loadService()
  }, [id])

  const service = !rawService ? undefined : renderLocalizedData<IService>(rawService, rawService?.locales ?? []);

  return (
    <AuthLayout>
      <SideBar></SideBar>
      {!service ? (
        <LoadingModule />
      ) : (
        <Content className="p-0 md:p-4 md:pt-0">
          <section className="flex flex-col w-full md:gap-8 grow pb-8">
            {/* banner */}
            <div className="relative w-full h-80">
              <img className="w-full h-full object-cover" src={service.banner?.src} alt={service.banner?.alt} />
              <div className="absolute bottom-0 w-full" style={{ bottom: 0 }}>
                <p className={twMerge(`md:rounded-tl-xl gradient p-3 text-white font-semibold bg-gradient-to-r`, `flex-col md:flex-row flex gap-4 justify-between items-center`)}>
                  {service.name}
                  <button onClick={() => push("/services", "back")} className="rounded-full bg-white text-primary px-3 md:bg-transparent md:text-white">
                    {`< `}{tran("Back")}
                  </button>
                </p>
              </div>
            </div>

            {/* Description */}
            {service.description && <div className="p-4 text-sm md:text-base md:p-0">
              {service.description}
            </div>}

            {/* features */}
            {service.features.length ?
              <div className="w-full grow p-4">
                <div className="grid grid-cols-1 md:grid-cols-3">
                  {service.features.sort((a, b) => a.index < b.index ? -1 : 1).map((feature) => (
                    <div
                      key={feature.name}
                      className={`w-full self-stretch flex flex-col items-start justify-start box-border p-4 not-first:border-l`}
                    >
                      <div className="self-center w-full flex flex-col justify-between items-center h-[160px] max-h-[160px]">
                        <div className="h-full w-[160px] rounded-full p-12 bg-gradient-to-t from-primary to-primary-fade flex flex-col justify-center items-center text-center">
                          <img src={feature.icon.src} alt={feature.icon.alt} className="w-20 h-20 object-contain" />
                          <small className="text-white mt-1 text-xs">{feature.name}</small>
                        </div>
                      </div>
                      <div className="mt-3 grow text-sm text-slate-600 overflow-y-auto text-center w-full">{feature.description}</div>
                    </div>
                  ))}
                </div>
              </div> : null
            }
            {
                  (service.price !== 0 || service.cta?.name !== '') &&
                  <div className="my-4 w-full flex flex-row items-center justify-between bg-slate-100 rounded-xl p-3">
                    {
                      service.price && service.currency ?
                        <div>
                          <p className="text-slate-500">{tran(`Starting from`)}:</p>
                          <h3>{`${service.currency?.value} ${service.price?.toLocaleString()}`}</h3>
                        </div>
                        : null
                    }
                    {
                      service.cta?.name !== '' ?
                        <div className="w-full flex justify-center items-center">
                          <button className="w-fit bg-primary text-white px-5 py-1 rounded-full" onClick={() => {
                            if (chatSettings.respondIO) {
                              buyerRecord && respondIOMessage(
                                buyerRecord,
                                rawService?.name ?? '',
                                '',
                                i18n.language
                              )
                              return
                            }
                          }}>
                            {service.cta?.name}
                          </button>
                        </div>
                        : null
                    }
                  </div>
                }

            {/* Options */}
            <div className="w-full grow">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4 md:p-0">
                {service.options.sort((a, b) => a.index < b.index ? -1 : 1).map((opt, idx) => (
                  <div key={`${opt.name}-${idx}`} className="w-full self-stretch rounded-2xl flex flex-col items-start justify-start border p-3 gap-3">
                    <div className="font-semibold text-primary mt-1 mb-2">{opt.name}</div>
                    <div className="self-center w-full flex flex-col justify-between items-center h-[160px] max-h-[160px]">
                      {
                        opt.images.length === 1 ?
                          <img src={opt.images[0]?.src} alt={opt.images[0]?.alt} className="object-cover h-full w-full rounded-lg" />
                          :
                          <Slider option={opt} />
                      }
                    </div>
                    {/* <div className="self-center my-3 flex justify-center">
                      <button className="h-[10px] w-[10px] rounded-full bg-primary mx-1 transition hover:scale-110" />
                      <button className="h-[10px] w-[10px] rounded-full bg-slate-400 mx-1 transition hover:scale-110" />
                      <button className="h-[10px] w-[10px] rounded-full bg-slate-400 mx-1 transition hover:scale-110" />
                    </div> */}
                    <div className="grow text-sm text-slate-600 overflow-y-auto">{opt.description}</div>
                    <div className="w-full flex justify-start my-2">
                      <IoPricetagOutline className="mr-2 text-slate-400" size={16} />
                      <h6 className="text-slate-400">{tran(`Starting from`)}</h6>
                    </div>
                    <div className="w-full flex flex-col">
                      <div className="flex justify-start items-center self-start">
                        <h6 className="text-primary text-xl">{getSymbolFromCurrency(opt.currency?.value ?? '')}</h6>
                        <h6 className="text-2xl text-primary font-bold">{opt.price?.toLocaleString() + "~"}</h6>
                      </div>
                      {
                        opt.cta ?
                          <div className="self-end">
                            <button
                              className="bg-primary text-white px-3 py-1 rounded-full hover:scale-110 transition"
                              onClick={() => {
                                if (chatSettings.respondIO) {
                                  buyerRecord && respondIOMessage(
                                    buyerRecord,
                                    rawService?.name ?? '',
                                    rawService?.options.find(o =>o.index === opt.index)?.name ?? '',
                                    i18n.language
                                  )
                                  return
                                }
                              }}
                            >
                              {opt.cta?.name}
                            </button>
                          </div>
                          : null
                      }
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </Content>
      )}
    </AuthLayout>
  );
};

export default ServiceDetails;

interface SliderProps {
  option: IServiceOption;
}

function Slider({
  option
}: SliderProps) {

  return (
    <div className="flex flex-col w-full">
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        autoplay={{
          delay: 5000,
        }}
        loop
        className="w-full"
        spaceBetween={0}
        slidesPerView={1}
        onSlideChange={() => console.log('slide change')}
        pagination={{ clickable: true }}
      >
        {option.images.map((img, idx) => (
          <SwiperSlide key={`option-${option.index}-slide-${idx}`} >
            <div className="w-full h-[160px] bg-slate-200 relative cursor-grab">
              <img src={img.src} alt={img.alt} className="w-full h-full object-cover rounded-xl" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

    </div>
  )
}