import { IonLoading } from "@ionic/react";
import { sentResetPwLink } from "app/helpers";
import { CenterContainer } from "components/Global/CenterContainer";
import GuestLayout from "components/GuestLayout";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export function ForgotPasswordPage() {

    const { t: tran } = useTranslation();
    const [email, setEmail] = useState('');
    const [state, setState] = useState<'idle' | 'sending' | 'sent'>('idle');
    const { goBack } = useHistory();

    console.log(`state`, state)

    const handleSubmit = useCallback(async () => {
        setState('sending');
        await sentResetPwLink(email);
        setState('sent')
    }, [email]);

    return (
        <GuestLayout>
            <CenterContainer>
                <div
                    className="p-8 rounded-xl bg-slate-100 w-full max-w-xl flex flex-col gap-4 justify-center items-center"
                >
                    <img
                        src='/assets/logos/Fulcrum Global Logo_Fulcrum Global-Primary.png'
                        alt="logo"
                        className="w-44 h-auto"
                    />
                    {
                        state !== 'sent' ?
                            <div className="flex flex-col gap-4 items-center justify-center w-full" >
                                <h2>{(`Please provide your email 请提供您的电子邮件`)}</h2>
                                <div className="flex flex-col gap-2 w-full" >
                                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)}
                                        placeholder={("email 电子邮件")}
                                        className="p-2 w-full"
                                    />
                                    <button
                                        disabled={email === '' || !emailRegex.test(email)}
                                        onClick={handleSubmit}
                                        className="rounded p-2 bg-primary text-white cursor-pointer disabled:bg-slate-500 disabled:cursor-not-allowed"
                                    >{('Submit 提交')}</button>
                                    <button
                                        className="rounded p-2 bg-slate-200 text-slate-500 cursor-pointer"
                                        onClick={() => goBack()}
                                    >{tran("Cancel 取消")}</button>
                                </div>
                            </div>
                            :
                            <div className="flex flex-col gap-2 items-center justify-center" >
                                <h2 className="text-xl text-primary font-bold mb-5" >{tran(`Reset Password Link Sent 重设密码连结已寄出`)}</h2>
                                <p className="text-sm text-center">{(`We have sent a reset password link to ${email}`)}<br />{`我们已经将重设密码的连结寄送至 ${email}`}</p>
                                <button
                                    onClick={() => {
                                        setState('idle')
                                        window.location.assign('/login');
                                    }}
                                    className="rounded p-2 bg-primary text-white cursor-pointer disabled:bg-slate-500 disabled:cursor-not-allowed mt-8"
                                >{tran("Finish 完成")}</button>
                            </div>
                    }
                    <IonLoading isOpen={state === 'sending'} />
                </div>
            </CenterContainer>
        </GuestLayout>
    )
}