import { IonAlert, IonContent, IonLoading, IonPage } from "@ionic/react";
import { AUTH } from "app/contexts/user-context";
import { useSearchParams } from "app/hooks/useSearchParams";
import { CenterContainer } from "components/Global/CenterContainer";
import { LoadingModule } from "components/Global/LoadingModule";
import { passwordRegex } from "components/my-account-views/ResetPasswordView";
import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import { useCallback, useEffect, useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";


interface Params {
    mode: string | undefined | null;
    oobCode: string | undefined | null;
}

export default function PublicResetPassword() {
    const [password, setPassword] = useState('');
    const [retypePassword, setRetypePassword] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [status, setStatus] = useState<'verifying' | 'error' | 'idle' | 'saving' | 'saved'>('verifying');

    const searchParams = useSearchParams();
    const params: Params = useMemo(() => ({
        mode: searchParams.get("mode"),
        oobCode: searchParams.get("oobCode"),
    }), [searchParams]);

    const checkPw = useCallback(() => {
        if (!password || !passwordRegex.test(password) || password !== retypePassword) {
            setDisabled(true)
            return
        }
        setDisabled(false)
    }, [password, retypePassword, setDisabled])

    useEffect(() => {
        checkPw()
    }, [password, retypePassword, checkPw])

    useEffect(() => {
        const verifyCode = async () => {
            if (!params.mode || !params.oobCode || params.mode !== 'resetPassword') {
                setStatus('error');
                return
            }
            try {
                await verifyPasswordResetCode(AUTH, params.oobCode);
                setStatus('idle')
            } catch (error) {
                setStatus('error');
                return
            }
        }
        verifyCode();

    }, [params])

    const handleSubmit = useCallback(async () => {

        setStatus('saving');
        // update password
        await confirmPasswordReset(AUTH, `${params.oobCode}`, password);

        setStatus('saved');
    }, [setStatus, password, params])

    const renderLogo = (
        <img 
            src="assets/logos/Fulcrum Global Logo_Fulcrum Global-Primary.png" 
            alt="logo"
            className="w-full h-20 object-contain mb-8"
        />
    )

    if (status === 'verifying')
        return (
            <IonPage>
                <IonContent fullscreen>
                    <CenterContainer>
                        <LoadingModule />
                    </CenterContainer>
                </IonContent>
            </IonPage>
        )

    if (status === 'error')
        return (
            <IonPage>
                <IonContent fullscreen>
                    <CenterContainer>
                        <div className="p-8 rounded-xl bg-slate-100 max-w-xxl m-auto flex flex-col gap-2 text-center" >
                            {renderLogo}
                            <h2 className="font-bold text-primary text-xl mb-4" >{`Something's not right... 好像出了点问题...`}</h2>
                            <p>{(`The reset link is either invalid or already expired.\n连结已失效或已过期。`)}</p>
                            <p>Please request for a new link <a href="/forgot-password" >here</a>.</p>
                            <p>请按<a href="/forgot-password" >这里</a>获取新的连结。</p>
                            <button className="px-4 py-2 bg-slate-200 text-slate-700 rounded w-full mt-4" onClick={() => window.location.assign('/')}>
                                {(`Back 返回`)}
                            </button>
                        </div>
                    </CenterContainer>
                </IonContent>
            </IonPage>
        )

    return (
        <IonPage>
            <IonContent fullscreen>
                <CenterContainer>
                    <div
                        className="w-full p-8 flex justify-center items-center flex-col gap-8"
                    >
                        {renderLogo}
                        <h2 className="text-xl font-sans" >{(`Reset Password 重设密码`)}</h2>
                        <div
                            className="bg-slate-100 rounded-xl p-8 w-fit flex flex-col gap-5 max-w-md"
                        >
                            <div className="flex flex-col gap-2" >
                                <label>{(`New Password 新密码`)}</label>
                                <input type='password' className="p-2"
                                    onChange={e => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="flex flex-col gap-2" >
                                <label>{(`Confirm New Password 确认密码`)}</label>
                                <input type='password' className="p-2"
                                    onChange={e => setRetypePassword(e.target.value)}
                                />
                            </div>
                            <div>
                                <small
                                    className={twMerge(
                                        disabled ? 'text-red-500' : 'text-green-700'
                                    )}
                                >Hint: Your new password should be 8 characters long and include at least 1 number, 1 uppercase letter, and 1 special character (e.g., !, @, #, $, etc.). Make it strong and unique for better security.
                                </small><br/>
                                <small
                                    className={twMerge(
                                        disabled ? 'text-red-500' : 'text-green-700'
                                    )}
                                >提示：您的新密码应为8个字符长，包括至少1个数字、1个大写字母和1个特殊字符（例如！、@、#、$等）。请确保密码强大且独一无二以提高安全性。</small>
                            </div>

                            <button
                                disabled={disabled}
                                className={twMerge(
                                    `bg-teal-500 text-white rounded-md p-2`,
                                    `disabled:bg-slate-200 disabled:text-slate-400`
                                )}
                                onClick={handleSubmit}
                            >{(`Update Password 更新密码`)}</button>
                        </div>
                    </div>
                </CenterContainer>
            </IonContent>
            <IonLoading isOpen={status === 'saving'} spinner='dots' />
            <IonAlert
        isOpen={status === 'saved'}
        header={("Your new password has been updated. 你的密码已成功更新")}
        onDidDismiss={() => setStatus('idle')}
        buttons={[
          {
            text:('Login 登录'),
            handler: async () => {
              window.location.assign('/login');
            }
          }
        ]}
      ></IonAlert>
        </IonPage>
    )
}