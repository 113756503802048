import { airTableClient } from "app/Client";
import { useUserContext } from "app/contexts/user-context";
import { AuthLayout } from "components/AuthLayout";
import { Content } from "components/Content";
import Skeleton from "components/Global/Skeleton";
import { SideBar } from "components/SideBar";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CgSpinner } from "react-icons/cg";
import { useHistory } from "react-router";
import { twMerge } from "tailwind-merge";
import { OrderItem } from "./MyPropertiesPage";
import { IService } from "app/types/services";
import { collection, getDocs } from "firebase/firestore";
import { DB } from "app/contexts/user-context";
import { LoadingModule } from "components/Global/LoadingModule";
import useContentLocales from "app/contexts/use-content-locale";

const HomePage: React.FC = () => {
  const { buyerRecord } = useUserContext();
  const [orders, setOrders] = useState<any[] | undefined>(undefined);
  const [rawServices, setServices] = useState<IService[] | undefined>(undefined);
  const { t: tran } = useTranslation();
  const { push } = useHistory()
  const { renderLocalizedData } = useContentLocales();

  // load services
  useEffect(() => {
    console.log('Home Loaded');
    const loadServices = async () => {
      const res = await getDocs(collection(DB, `services`));
      const servicesData = res.docs.map(dd => ({ id: dd.id, ...dd.data() } as IService)).filter(s => s.publish);
      setServices(servicesData);
    }
    loadServices();
  }, []);

  const services = !rawServices ? rawServices : rawServices.map(ser => ({
    ...renderLocalizedData<IService>(ser, ser.locales ?? [])
  } as IService))

  useEffect(() => {
    if (!buyerRecord) {
      return
    }
    const loadOrders = async () => {
      const ids = buyerRecord.fields["Order ID"];
      const orderRes = await airTableClient.getOrdersByIds(ids.filter((a, i) => i < 2));
      setOrders([...orderRes.filter((x, i) => !!x && x.fields.Status !== 'Swapped' && i < 2)]);
    }
    loadOrders();
  }, [buyerRecord]);

  return (
    <AuthLayout>
      <SideBar sideMenu={false} />
      <Content>
        <div className="w-full h-max md:h-auto grow flex flex-col gap-8 md:my-8">
          {/* welcome banner */}
          <div
            style={{
              backgroundImage: `url(assets/img/login-bg.jpeg)`,
            }}
            className={twMerge(`w-full h-fit rounded-2xl p-4 md:p-8 text-white bg-cover grid grid-cols-1 lg:grid-cols-2 md:gap-y-6`)}
          >
            {/* welcome message */}
            <div className="p-4 md:p-0 flex flex-col items-start justify-center grow col-span-2 md:col-span-1">
              {buyerRecord ?
                <>
                  <h2 className="font-narrow text-2xl md:text-4xl">{tran(`Welcome Back!`)}</h2>
                  <p className="font-semibold text-2xl md:text-4xl">{buyerRecord.fields["Buyer Name"]}</p>
                </>
                :
                <div className="flex flex-col gap-2">
                  <Skeleton className="w-3/4 h-12" />
                  <Skeleton className="w-full h-12" />
                </div>
              }
            </div>
            {/* latest properties */}
            {!orders ?
              <div className="w-full h-fit md:h-full flex items-center justify-center">
                <CgSpinner className="w-12 h-12 text-white opacity-30 animate-spin" />
              </div>
              :
              <div className="flex flex-col md:flex-row gap-4 md:gap-6 md:justify-center lg:justify-end w-full">
                {
                  orders.map(order => <OrderItem key={order.id} order={order} className="w-full md:max-w-[45%] grow h-fit self-stretch md:h-[100%]" />)
                }
              </div>
            }
          </div>
          {/* promotion and services grid */}
          <section className="w-full h-fit md:h-full grid grid-cols-3 gap-8">
            {/* promote banner */}
            <div className="h-full flex flex-col col-span-3 md:col-span-1">

              <h3 className="pb-4">{tran(`Promotion`)}</h3>
              <div className="h-full bg-slate-200 rounded-2xl flex flex-col items-center justify-center p-4 relative">
                <img src="/assets/temp/homepage.jpg" alt=""
                  className="absolute w-full h-full object-cover rounded-2xl"
                />
                <h3 className="font-medium text-2xl h-full flex flex-col items-center justify-center text-center text-white relative" >{tran(`Coming Soon`)}</h3>
                <button className={twMerge(
                  `mt-2 self-center md:self-end justify-self-end`,
                  `rounded-full bg-primary text-white py-1 px-4 text-sm relative`
                )} >{tran(`Apply Now`)}</button>
              </div>
            </div>

            {/* services list */}
            <div className="col-span-3 md:col-span-2 w-full h-full flex flex-col">
              <h3 className="pb-4" >{tran(`Value-Added Services`)}</h3>
              <div className="h-full grid grid-cols-2 grid-rows-2 gap-4 md:gap-8 text-white">
                {
                  !services ? <LoadingModule /> :
                    services.sort((a, b) => (a.index ?? 0) < (b.index ?? 0) ? -1 : 1).map((service, idx) => (
                      <div
                        key={service.id}
                        className={twMerge(
                          `h-full rounded-2xl bg-primary text-white overflow-hidden md:min-h-[128px]`,
                          `flex gap-4 items-center md:justify-center relative cursor-pointer group col-span-2`,
                          `px-12 md:px-6 py-6 justify-start`,
                          idx < 1 ? 'md:col-span-2' : 'md:col-span-1'
                        )}
                        onClick={() => push(`/services/${service.id}`)}
                      >
                        <img src="/assets/img/general-bg.jpg" alt="Furniture & Home Design Solutions"
                          className={twMerge(
                            `absolute z-0 w-full h-full top-0 left-0 object-cover rounded-2xl`,
                            `group-hover:scale-110 transition`
                          )}
                        />
                        {service.icon?.src && 
                        <img 
                          src={service.icon.src} alt={service.name}  
                          className={twMerge(`relative 'w-14 h-14`)}
                        />}
                        <p className={twMerge(
                          `relative w-fit text-start inline-block w-3/4`,
                          idx < 1 ? 'md:w-fit' : 'md:w-1/2'
                        )} >{service.name}</p>
                      </div>
                    ))
                }
              </div>
            </div>
          </section>
        </div>
      </Content>
    </AuthLayout>
  );
};

export default HomePage;
