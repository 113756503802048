import { IBuyerRecord } from "./buyer";

export enum IBuyerInvitationStatus{
    'Not Invited' = 'Not Invited',
    Pending = 'pending',
    Cancelled = 'cancelled',
    Active = 'active',
    Revoked = 'revoked'
}

export interface IBuyerInvitationSearchResult extends IBuyerRecord{
    inviteStatus: IBuyerInvitationStatus;
    isUser?: boolean;
}

export interface IBuyerInvitation{
    id: string;
    status: IBuyerInvitationStatus;
    buyerId: string;
    name: string;
    email: string;
    token: string;
}
