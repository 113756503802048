import { IonAccordion, IonAccordionGroup, useIonRouter } from "@ionic/react";
import { IOrder } from "app/types/order";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { BiAlarm, BiCheck, BiCheckCircle, BiChevronDown } from "react-icons/bi";
import { twMerge } from "tailwind-merge";
import { TbAlertSquareRoundedFilled } from 'react-icons/tb';

interface OrderStageItem {
  name: string;
  status: boolean;
  alert?: boolean;
}

interface OrderStage {
  index: number;
  name: string;
  items: OrderStageItem[];
}

interface Props {
  order: IOrder;
}

export function PropertyStatusSection({ order }: Props) {
  const { push } = useIonRouter();
  const { t: tran } = useTranslation();

  const unitSplitted = order.fields["Unit Number"].split(` `);
  const displayUnit = unitSplitted[unitSplitted.length - 1];

  const hasPoa: boolean = ((order?.poa?.length || 0) <= 0);
  const finalPaymentDue: boolean = (
    (order.fields.Status !== 'Completed') &&
    (dayjs(order?.fields["Last Payment due date"]).diff(dayjs(), 'days') < 30)
  );

  const hasFinalPayment = order.fields.Status === "Completed";

  const income1 = Array.isArray(order.fields["Income Criteria 1"]) ? order.fields["Income Criteria 1"] : [order.fields["Income Criteria 1"]];
  const income2 = Array.isArray(order.fields["Income Criteria 2"]) ? order.fields["Income Criteria 2"] : [order.fields["Income Criteria 2"]];

  // function to handle the order status ui
  const getOrderStages = (order: IOrder): OrderStage[] => {
    const stages: OrderStage[] = [];
    stages.push({
      name: tran("Reservation"),
      index: 0,
      items: [
        {
          name: tran("Booking Fee Received"),
          status: true,
        },
      ],
    });

    stages.push({
      name: tran("Exchange"),
      index: 1,
      items: [
        {
          name: tran("First Deposit"),
          status: hasFinalPayment ? true : (order.fields["Paid %(adj)"] ?? 0) >= (income1.reduce((p, v) => p + v, 0) ?? 0),
          // status: true
        },
        {
          name: tran("Second Deposit"),
          status: hasFinalPayment ? true : (order.fields["Paid %(adj)"] ?? 0) >= (income2.reduce((p, v) => p + v, 0) ?? 0),
        },
        {
          name: tran("Signed SPA"),
          status: !!order.fields["SPA post date"],
        },
      ],
    });

    stages.push({
      name: tran("Completion"),
      index: 2,
      items: [
        {
          name: tran("Final Payment"),
          status: hasFinalPayment,
          alert: finalPaymentDue
        },
        {
          name: tran("POA Form Submitted"),
          status: (order.poa !== undefined) && !!((order.poa?.length || 0) > 0),
          alert: hasPoa
        },
        {
          name: tran("Inspection"),
          status: false,
        },
        {
          name: tran("Handover Package"),
          status: false,
        },
      ],
    });

    return stages;
  };

  const stages = getOrderStages(order);
  return (
    <>
      {/* desktop */}
      <div className="hidden md:block">
        {/* title */}
        <p className={twMerge(`rounded-t-xl gradient p-3 text-white font-semibold bg-gradient-to-r`, `flex gap-4 justify-between items-center`)}>
          {`${order.project.fields["Project Name"]} - ${displayUnit}`}
          <button onClick={() => push("/my-properties", "back")}>{`< `}{tran(`Back`)}</button>
        </p>

        <div className="p-3 flex gap-4">
          {/* image */}
          {order.project?.fields?.Photos?.[0]?.thumbnails?.large?.url ? (
            <img alt="" src={order.project?.fields?.Photos?.[0]?.thumbnails?.large?.url} className="w-36 h-36 bg-slate-200 rounded-md" />
          ) : (
            <div className="w-36 h-36 bg-slate-200 rounded-md"></div>
          )}

          {/* status indicators */}
          <div className="grow flex flex-col">
            {/* headers */}
            <div className="w-full grid grid-cols-3">
              {stages.map((s) => (
                <h3 key={`order-stage-header-${s.index}`} className="p-2 text-start ml-3 font-semibold text-primary">
                  {s.name}
                </h3>
              ))}
            </div>
            {/* steps */}
            <div className="w-full h-fit relative mb-2">
              <div className="w-full h-full absolute grid grid-cols-3 ml-5">
                {stages.map((s) => {
                  const active = s.items.filter((item) => item.status).length === s.items.length;
                  const nextStage = s.index === stages.length - 1 ? null : stages[s.index + 1];
                  const nextActive = nextStage ? nextStage.items.filter((item) => item.status).length === nextStage.items.length : false;
                  return (
                    <div key={`order-stage-lines-${s.index}`} className="w-full h-full flex items-center justify-center">
                      <div
                        className={twMerge(
                          `w-full h-2 bg-gradient-to-r`,
                          s.index === 0 || active ? "from-primary" : "from-gray-300",
                          nextActive ? "to-primary" : "to-gray-300",
                          !nextStage && "opacity-0"
                        )}
                      ></div>
                    </div>
                  );
                })}
              </div>
              <div className="w-full grid grid-cols-3 relative">
                {stages.map((s) => (
                  <div key={`order-stage-status-icon-${s.index}`} className="flex items-center justify-start ml-5">
                    <img
                      src={s.items.filter((item) => item.status).length === s.items.length ? `/assets/img/stage-active.svg` : `/assets/img/stage-inactive.svg`}
                      alt=""
                      className={twMerge(`w-auto h-10 object-contain`)}
                    />
                  </div>
                ))}
              </div>
            </div>

            {/* items */}
            <div className="w-full grid grid-cols-3">
              {stages.map((s) => (
                <div key={`order-stage-items-${s.index}`} className="flex w-full flex-col">
                  {s.items.map((item, i) => (
                    <div className="flex gap-1 items-center relative" key={`order-stage-${s.index}-item-${item.name}-${i}`}>
                      {
                        item.alert ?
                          <div className="absolute right-0 top-0 w-fit h-fit">
                            <TbAlertSquareRoundedFilled className="w-5 h-5 text-red-500" />
                          </div>
                          : null
                      }
                      <BiCheck className={twMerge(`w-5 h-5`, item.status ? "text-primary" : "opacity-0")} />
                      <small className={twMerge(item.status ? "text-slate-800" : "text-gray-500")}>{item.name}</small>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* mobile */}
      <div className="md:hidden">
        {/* hero */}
        <div className="bg-slate-800 h-[25vh] w-full relative">
          {order.project?.fields?.Photos?.[0]?.thumbnails?.large?.url ? (
            <img
              alt=""
              src={order.project?.fields?.Photos?.[0]?.thumbnails?.large?.url}
              className="w-full h-full absolute opacity-0 transition"
              onLoad={(img) => {
                img.currentTarget.classList.remove("opacity-0");
                img.currentTarget.classList.add("opacity-60");
              }}
            />
          ) : null}
          <div
            className="relative w-full h-full flex flex-col items-center justify-start p-4"
          >
            <button
              onClick={() => push("/my-properties", "back")}
              className="self-start text-white"
            >{`< `}{tran(`Back`)}</button>
            <h2 className="relative text-white text-2xl font-semibold text-center grow flex items-center">{`${order.project.fields["Project Name"]}\n${order.fields["Unit Number"]}`}</h2>
          </div>
        </div>

        {/* Mobile status indicators */}
        <div className="md:hidden p-4">
          <div className={twMerge(`rounded-t-xl gradient p-3 text-white font-semibold bg-gradient-to-r`)}>{tran(`Order Status`)}</div>
          <IonAccordionGroup>
            {stages.map((stage) => (
              <IonAccordion key={stage.name}>
                <div slot="header" className="flex items-center justify-between p-4 border-b">
                  <img
                    src={stage.items.filter((item) => item.status).length === stage.items.length ? `/assets/img/stage-active.svg` : `/assets/img/stage-inactive.svg`}
                    alt=""
                    className={twMerge(`w-auto h-8 object-contain`)}
                  />
                  <div className={twMerge(`flex gap-2 items-center`, stage.items.filter((item) => item.status).length === stage.items.length ? `font-semibold text-primary` : `text-gray-500`)}>
                    {stage.items.filter((item) => item.status).length === stage.items.length ? <BiCheckCircle className="text-primary w-6 h-6" /> : <BiAlarm className="text-gray-400 w-6 h-6" />}
                    {stage.name}
                    <BiChevronDown />
                  </div>
                </div>
                <div className="p-0 grid grid-cols-1 border-b bg-slate-100 shadow-inner" slot="content">
                  <div className="flex flex-col col-span-1 pl-8 w-full">
                    {stage.items.map((item) => (
                      <div key={item.name} className="p-4 flex items-center gap-2 w-full justify-end border-l-2 border-slate-200 relative">
                        <div className="rounded-full w-3 h-3 bg-slate-300 self-center mr-auto relative right-[23px]">
                          {
                            item.alert ?
                              <div className="absolute -right-1 -top-1 w-fit h-fit">
                                <TbAlertSquareRoundedFilled className="w-5 h-5 text-red-500" />
                              </div>
                              : null
                          }
                        </div>
                        <BiCheck className={twMerge(`w-5 h-5`, item.status ? "text-primary" : "opacity-0")} />
                        <small className={twMerge(item.status ? "text-primary" : "text-gray-500")}>{item.name}</small>
                      </div>
                    ))}
                  </div>
                </div>
              </IonAccordion>
            ))}
          </IonAccordionGroup>
        </div>
      </div>
    </>
  );
}
