import useContentLocales from "app/contexts/use-content-locale";
import { DB } from "app/contexts/user-context";
import { IUserGuideItem } from "app/types/myAccounts";
import { Content } from "components/Content";
import { LoadingModule } from "components/Global/LoadingModule";
import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const getUserGuides = async () => {
  const res = await getDocs(collection(DB, `user-guides`));
  return res.docs.map(item => ({ id: item.id, ...item.data() } as IUserGuideItem));
}

function UsefulGuidelineView() {
  const [rawItems, setItems] = useState<IUserGuideItem[] | undefined>(undefined);
  const { renderLocalizedData } = useContentLocales();
  const { t: tran } = useTranslation();

  useEffect(() => {
    const loadContent = async () => {
      const data = await getUserGuides();
      setItems(data);
    }
    loadContent()
  }, [])

  const items = !rawItems ? rawItems : rawItems.map(item => ({
    ...renderLocalizedData<IUserGuideItem>(item, item.locales ?? [])
  } as IUserGuideItem))

  return (
    <Content>
      <div>
        {
          !items ? <LoadingModule /> :
            !items.length ? <div>{tran(`No User Guides Found`)}</div> :
              <div
                className="divide-y flex flex-col w-full"
              >
                <div className="py-8 px-2" >
                  {tran(`You can download our useful guides here:`)}
                </div>
                {
                  items.map(guide => (
                    <a key={guide.id} href={guide.file.url} target='_blank' download rel="noreferrer"
                      className="p-2 w-full no-underline hover:bg-teal-100 transition flex justify-between items-center group"
                    >
                      <span
                        className="group-hover:translate-x-1 transition"
                      >{guide.label}</span>
                      <button
                        className="p-2"
                      >{tran(`Download`)}</button>
                    </a>
                  ))
                }
              </div>
        }
      </div>
    </Content>
  );
}

export default UsefulGuidelineView;
